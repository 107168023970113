import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { optimizeImageCardDesktop } from "helpers/imgix";
import { AllyCardPillar } from "components";
import { Typography } from "components/SemanticComponents";
import { ReactComponent as Timer } from "assets/Timer.svg";

const CardLi = styled.li`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  background: ${(props) => props.theme.colors.primaryBackground};
  cursor: pointer;
  a {
    text-decoration: none;
  }

  ${(props) => {
    switch (props.pillar?.id) {
      case 1:
        return {
          borderTopColor: `${props.theme.colors.heroBlue}`,
          borderWidth: `3px 1px 1px 1px`,
          borderStyle: `solid`,
        };
      case 2:
        return {
          borderTopColor: `${props.theme.colors.heroPink}`,
          borderWidth: `3px 1px 1px 1px`,
          borderStyle: `solid`,
        };
      case 3:
        return {
          borderTopColor: `${props.theme.colors.heroGreen}`,
          borderWidth: `3px 1px 1px 1px`,
          borderStyle: `solid`,
        };
      case 4:
        return {
          borderTopColor: `${props.theme.colors.heroPurple}`,
          borderWidth: `3px 1px 1px 1px`,
          borderStyle: `solid`,
        };
      default:
        return {
          borderTopColor: `rgba(0, 0, 0, 0.15)`,
          borderWidth: `1px`,
          borderStyle: `solid`,
        };
    }
  }}
  border-right-color: rgba(0, 0, 0, 0.15);
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-left-color: rgba(0, 0, 0, 0.15);
  box-shadow: ${(props) => props.theme.boxShadow.md};
  border-radius: 0.85em;
  outline: none;

  h2,
  p {
    margin: 0;
  }
  h2 {
    ${(props) => {
      switch (props.active) {
        case 1:
          return { marginTop: "-40px" };
        case 0:
          return { marginTop: "-40px" };
        default:
          return { marginTop: "0px" };
      }
    }}
    padding: ${(props) =>
      `${props.theme.spacing.md} ${props.theme.spacing.md} ${props.theme.spacing.sm} ${props.theme.spacing.md}`};
  }

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    margin-bottom: ${(props) => props.theme.spacing.xs};
    overflow: hidden;
  }

  &:hover,
  &:focus-within {
    ${(props) => {
      switch (props.pillar?.id) {
        case 1:
          return {
            borderLeftColor: `hsla(216, 100%, 48%, 0.6)`, // blue
            borderBottomColor: `hsla(216, 100%, 48%, 0.6)`,
            borderRightColor: `hsla(216, 100%, 48%, 0.6)`,
          };
        case 2:
          return {
            borderLeftColor: `hsla(330.8, 92.2%, 69.8%, 0.6)`, // pink
            borderBottomColor: `hsla(330.8, 92.2%, 69.8%, 0.6)`,
            borderRightColor: `hsla(330.8, 92.2%, 69.8%, 0.6)`,
          };
        case 3:
          return {
            borderLeftColor: `hsla(144.2, 54.9%, 55.7%, 0.6)`, // green
            borderBottomColor: `hsla(144.2, 54.9%, 55.7%, 0.6)`,
            borderRightColor: `hsla(144.2, 54.9%, 55.7%, 0.6)`,
          };
        case 4:
          return {
            borderLeftColor: `hsla(272.6, 32.3%, 47.38%, 0.6)`, // purple
            borderBottomColor: `hsla(272.6, 32.3%, 47.38%, 0.6)`,
            borderRightColor: `hsla(272.6, 32.3%, 47.38%, 0.6)`,
          };
        default:
          return {
            borderTopColor: `hsla(216, 100%, 48%, 0.6)`, // blue
            borderLeftColor: `hsla(216, 100%, 48%, 0.6)`,
            borderBottomColor: `hsla(216, 100%, 48%, 0.6)`,
            borderRightColor: `hsla(216, 100%, 48%, 0.6)`,
          };
      }
    }}
    box-shadow: ${(props) => props.theme.boxShadow.cardHover};
  }
`;

const CardMain = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    font-size: ${(props) => props.theme.fontSize.md};
    font-weight: bold;
    padding: ${(props) =>
      `${props.theme.spacing.sm} ${props.theme.spacing.md}`};
    line-height: ${(props) => props.theme.lineHeight.md};
  }
  p {
    font-size: ${(props) => props.theme.fontSize.xxs};
    line-height: ${(props) => props.theme.lineHeight.md};
    padding: ${(props) => `0 ${props.theme.spacing.md}`};
  }
`;

const Img = styled.img`
  border-radius: 0.73em 0.73em 0 0;
  order: -1;
  max-width: 100%;
  height: 100px;
  object-fit: cover;
`;

const Span = styled.span`
  visibility: ${(props) => (props.editCard === false ? "hidden" : "visible")};
  ${(props) => {
    switch (props.active) {
      case 1:
        return {};
      case 0:
        return {};
      default:
        return { display: `none` };
    }
  }}
  position: relative;
  top: -107px;
  right: -223px;
  background: ${(props) =>
    props.active === 1 ? props.theme.colors.heroBlue : "white"};
  border: ${(props) =>
    props.active === 1 ? "none" : `1px solid ${props.theme.colors.heroBlue}`};
  font-size: x-large;
  border-radius: 50%;
  color: white;
  width: 35px;
  height: 35px;
  z-index: 10;
`;

const Icon = styled.div`
  visibility: ${(props) =>
    props.active === 0 || props.editCard === false ? "hidden" : "visible"};
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  margin: ${(props) =>
    `auto ${props.theme.spacing.sm} ${props.theme.spacing.sm} ${props.theme.spacing.sm}`};
`;

const TimerContainer = styled.div`
  display: flex;
  height: 20px;
  align-items: center;

  svg {
    height: 100%;
    width: fit-content;
  }
  p {
    font-size: ${(props) => props.theme.fontSize.xs};
    margin: 0 0 0 2px;
    padding: 0;
  }
`;

//TODO: notes on this component, this card is a bit fiddly because when I separated the two concerns of it being an add option or a link, there is a bit of spacing that needed to be resolved. The plan next here is to separate the cards into an edit card and a click card, or change the parent component up so the edit program is only called once and not in multiple places where edit isn't needed.

export default function AllyCard({ select, link, data, editCard }) {
  const history = useHistory();

  const buttonToggle = (props) => {
    let id = props;
    return editCard ? select(id) : history.push(link);
  };

  return (
    <CardLi
      role="menuitem"
      ariaLabel={
        data.active === 1
          ? "Card is added to your program."
          : "Card is removed from your program."
      }
      active={data?.active}
      pillar={data?.pillar}
      tabIndex="0"
      onClick={() => buttonToggle(data.id)}
      data-testid={`allyCard${data.id}`}
    >
      <CardMain pillar={data?.pillar}>
        <Span active={data?.active} editCard={editCard}>
          <Icon active={data?.active} editCard={editCard}>
            &#10003;
          </Icon>
        </Span>

        <Typography.Title level="h2">{data?.title}</Typography.Title>

        <Img
          loading="lazy"
          src={optimizeImageCardDesktop(
            data?.image_url ? data?.image_url : data?.image
          )}
          alt="decorative"
        />

        {data.pillar ? <AllyCardPillar pillar={data?.pillar} /> : null}

        <Typography.Paragraph>
          {data?.external_description
            ? data?.external_description
            : data?.extract}
        </Typography.Paragraph>
      </CardMain>

      <Footer>
        {data?.lesson_time ? (
          <TimerContainer>
            <Timer aria-label={"time indicator"} />
            <Typography.Paragraph level="p2">
              {data?.lesson_time}
            </Typography.Paragraph>
          </TimerContainer>
        ) : null}
      </Footer>
    </CardLi>
  );
}
