import { Loader } from "components";
import Navbar from "components/Navbar/Navbar";
import { useAuth } from "context/auth";
import { lazyWithRetry } from "helpers/lazyWithRetry";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { UserStoreProvider } from "utils/GlobalState";

const PublicRoute = lazyWithRetry(() => import("routes/PublicRoute"));
const PrivateRoute = lazyWithRetry(() => import("routes/PrivateRoute"));

const AccountEditScreen = lazyWithRetry(() =>
  import("screens/AccountEditScreen")
);
const AccountScreen = lazyWithRetry(() => import("screens/AccountScreen"));
const ArticleScreen = lazyWithRetry(() => import("screens/ArticleScreen"));
const CompanyPolicyScreen = lazyWithRetry(() =>
  import("screens/CompanyPolicyScreen")
);
const Dashboard = lazyWithRetry(() => import("screens/Dashboard"));
const ExploreScreen = lazyWithRetry(() => import("screens/ExploreScreen"));
const ForgotPasswordScreen = lazyWithRetry(() =>
  import("screens/ForgotPasswordScreen")
);
const GPPrivacyScreen = lazyWithRetry(() => import("screens/GPPrivacyScreen"));
const GPTermsScreen = lazyWithRetry(() => import("screens/GPTermsScreen"));
const LessonScreen = lazyWithRetry(() => import("screens/LessonScreen"));
const LoginScreen = lazyWithRetry(() => import("screens/LoginScreen"));
const MyInsightsScreen = lazyWithRetry(() =>
  import("screens/MyInsightsScreen")
);
const MyProgramScreen = lazyWithRetry(() => import("screens/MyProgramScreen"));
const LabScreen = lazyWithRetry(() => import("screens/LabScreen"));
const MyProgramEditScreen = lazyWithRetry(() =>
  import("screens/MyProgramEditScreen")
);
const NotFound = lazyWithRetry(() => import("screens/404Screen"));
const OurStories = lazyWithRetry(() => import("screens/OurStories"));
const PostRegistrationPersonaPickerScreen = lazyWithRetry(() =>
  import("screens/PostRegistrationPersonaPickerScreen")
);
const ProfessionalVisionScreen = lazyWithRetry(() =>
  import("screens/ProfessionalVisionScreen")
);
const RegisterScreen = lazyWithRetry(() => import("screens/RegisterScreen"));
const CustomRegisterScreen = lazyWithRetry(() =>
  import("screens/CustomRegisterScreen")
);
const PlatinumMemberRegisterScreen = lazyWithRetry(() =>
  import("screens/PlatinumMemberRegisterScreen")
);

const VerifyEmailScreen = lazyWithRetry(() =>
  import("screens/VerifyEmailScreen")
);

const StoriesFormScreen = lazyWithRetry(() =>
  import("screens/StoriesFormScreen")
);
const StoryScreen = lazyWithRetry(() => import("screens/StoryScreen"));

const SamlAuthorisationScreen = lazyWithRetry(() =>
  import("screens/SamlAuthorisationScreen")
);

const PasswordResetScreen = lazyWithRetry(() =>
  import("screens/PasswordResetScreen")
);

const ProfessionalVisionPDF = lazyWithRetry(() =>
  import("components/ProfessionalVisionPDF")
);

export default function Routes() {
  const { authToken } = useAuth();
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        {!!authToken && <Navbar />}
        <Switch>
          <PublicRoute path="/login">
            <LoginScreen />
          </PublicRoute>
          <PublicRoute path="/password">
            <ForgotPasswordScreen />
          </PublicRoute>
          <PublicRoute path="/reset">
            <PasswordResetScreen />
          </PublicRoute>
          <PublicRoute path="/register">
            <UserStoreProvider>
              <RegisterScreen />
            </UserStoreProvider>
          </PublicRoute>
          <PublicRoute exact path="/custom-register">
            <UserStoreProvider>
              <CustomRegisterScreen />
            </UserStoreProvider>
          </PublicRoute>
          <PublicRoute exact path="/platinum-member-register">
            <UserStoreProvider>
              <PlatinumMemberRegisterScreen />
            </UserStoreProvider>
          </PublicRoute>
          <PublicRoute path="/verify-email">
            <VerifyEmailScreen />
          </PublicRoute>
          <PublicRoute path="/saml-authorisation">
            <SamlAuthorisationScreen />
          </PublicRoute>
          <PublicRoute exact path="/privacy-statement">
            <GPPrivacyScreen />
          </PublicRoute>
          <PublicRoute exact path="/terms-and-conditions">
            <GPTermsScreen />
          </PublicRoute>
          <PrivateRoute exact path="/">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/lesson/:step">
            <LessonScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/my-insights">
            <MyInsightsScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/program">
            <MyProgramScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/labs/:slug">
            <LabScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/program/personalise">
            <MyProgramEditScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/account">
            <AccountScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/account/edit">
            <AccountEditScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/explore">
            <ExploreScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/company-policy">
            <CompanyPolicyScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/blogpost/:slug">
            <ArticleScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/our-stories">
            <OurStories />
          </PrivateRoute>
          <PrivateRoute exact path="/stories/:slug">
            <StoryScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/stories-form/:slug">
            <StoriesFormScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/current-situation">
            <PostRegistrationPersonaPickerScreen />
          </PrivateRoute>
          <PrivateRoute exact path="/my-insights/your-professional-vision">
            <ProfessionalVisionScreen />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/my-insights/professional-vision-pdf-viewer"
          >
            <ProfessionalVisionPDF />
          </PrivateRoute>

          <PublicRoute exact path="/version">
            {() => {
              return <>{process.env.REACT_APP_VERSION}</>;
            }}
          </PublicRoute>
          <PublicRoute path="*">
            <NotFound />
          </PublicRoute>
        </Switch>
      </Suspense>
    </Router>
  );
}
